// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
// import * as ActiveStorage from "@rails/activestorage"
// import "channels"

import Slider from './web/slider'
import Alerts from './web/alerts'
import Favourites from './web/favourites'
import ShoppingCart from './web/shopping_cart'
import Order from './web/order'
import Locale from './web/locale'
import ProductFilters from './web/product_filters'
import Cookies from './web/cookies'

import './web/wow'
import './web/script'
import './web/lazyload'
import './web/jquery.fancybox'
import './web/parallax-scroll'
import './web/bootstrap.min'
import './web/search_product'
//import './web/validate'
import Form from './web/form'

import '@popperjs/core'
import 'bootstrap'
import 'bootstrap-select'
import 'jquery-ui'
import 'owl.carousel'
import 'jquery.appear'
import 'cookieconsent/build/cookieconsent.min'

Rails.start()
Turbolinks.start()
// ActiveStorage.start()

document.addEventListener('turbolinks:load', function (event) {
  Slider.init();
  Alerts.init();
  Cookies.init();
  Favourites.init();
  ShoppingCart.init();
  ProductFilters.init();
  Order.init();
  Locale.init();
  Form.init();
});

document.addEventListener('turbolinks:before-cache', function (event) {
  Slider.destroy();
});
