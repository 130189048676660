var cookies = (function($) {
  function init(){
    askCookiesPermission();
  }

  function askCookiesPermission() {
      window.cookieconsent.initialise({
          "palette": {
            "popup": {
              "background": "#1e7b1e",
              "text": "#fff"
            },
            "button": {
              "background": "#145014",
              "text": "#ffff"
            }
          },
          "theme": "classic",
          "position": "bottom",
          "content": {
            "message": "Продължавайки да използвате този сайт, вие се съгласявате с използването на бисквитки.",
            "dismiss": "Разбрах!",
            "link": "Още ...",
            "href": "https://karandila.eu/bg/privacy-policy"
          }
      });
  }

  return {
    init: init
  };

})(jQuery);

export default cookies;